import React ,{ useState }from 'react'
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';

import dataTesti from './dataTesti'
import ItemTesti from './ItemTesti'
/*
*author:tdms410
*/

function Testimony() {
    const [current, setCurrent] = useState(0);
	const length = dataTesti.length;
	
	const nextSlide = () => {
		setCurrent(current === length - 1 ? 0 : current + 1);
	};

	const prevSlide = () => {
		setCurrent(current === 0 ? length - 1 : current - 1);
	};

	if (!Array.isArray(dataTesti) || length <= 0) {
		return null;
	}
    return (
        <>
            <div className="section-heading mt-5 mb-3 text-center">
                <h6 className="__subtitle">Témoignages :</h6>
            </div>
            <section className="page-content row">
				{dataTesti.map((item) => (
					<ItemTesti key={item.id} item={item} className='no-phone' />
				))}
			</section>
            <section className="sliders mb-5 mt-5">

				<FaArrowAltCircleLeft className='left-arrow' onClick={prevSlide} />
				<FaArrowAltCircleRight className='right-arrow' onClick={nextSlide} />				

				{dataTesti.map((slide, index) => {

					return (
					
						<div
							className={index === current ? 'slide active' : 'slide'}
							key={index}
							id='phone'
						>
							{index === current && (
								<ItemTesti key={index} item={slide} className='image'/>
							)}
						</div>
					);
				})}

			</section>
        </>
    )
}

export default Testimony