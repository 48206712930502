
import React from 'react';
import './Sponsor.css';
import spon from '../../../images/Sponsors/images (1).png'
import spon1 from '../../../images/Sponsors/Logo_Minpostel.jpg'
import spon2 from '../../../images/Sponsors/téléchargement.png'
import spon3 from '../../../images/Sponsors/Option-01-JPEG.jpg'
import spon4 from '../../../images/Sponsors/cropped-Logo_obotama-.png'
import spon6 from '../../../images/Sponsors/logo-sommet-afrique-france-horizontal.png'
import spon7 from '../../../images/Sponsors/Oser-l-Afrique-logo.jpg'
import spon8 from '../../../images/Sponsors/cropped-Coat_of_arms_of_Cameroon.svg.png'
import spon9 from '../../../images/Sponsors/UNDP.png'
import spon10 from '../../../images/Sponsors/ayada.png'

const Sponsor = () => {
    return (
        <div className='content-spon mt-5'>
            <div className="slider">
                <div className="slide-track">
                    <div className="slide">
                        <img src={spon1} height={200} width={200} alt="" />
                    </div>
                    <div className="slide">
                        <img src={spon} height={200} width={200} alt="" />
                    </div>
                    <div className="slide">
                        <img src={spon2} height={200} width={200} alt="" />
                    </div>
                    <div className="slide">
                        <img src={spon3} height={200} width={200} alt="" />
                    </div>
                    <div className="slide">
                        <img src={spon4} height={200} width={200} alt="" />
                    </div>
                   
                    <div className="slide">
                        <img src={spon6} height={200} width={200} alt="" />
                    </div>
                    <div className="slide">
                        <img src={spon7} height={200} width={200} alt="" />
                    </div>
                    <div className="slide">
                        <img src={spon8} height={200} width={200} alt="" />
                    </div>
                    <div className="slide">
                        <img src={spon9} height={200} width={200} alt="" />
                    </div>
                    <div className="slide">
                        <img src={spon10} height={200} width={200} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sponsor;
