import React from 'react'
import './Testimony.css'

function ItemTesti({ item }) {
	return (
		<>
			<div className="cardT mt-md-5 mt-5">
				<img src={item.img} class="cardT-img" mt-5></img>
				<div class="cardT-info ">
					<p class="text-ody">{item.message}</p>
					<p class="text-itle"><span style={{fontSize:'15px'}}>{item.name}</span><br/>{item.metier}</p>
				</div>
			</div>
		</>
	)
}

export default ItemTesti