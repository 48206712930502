import React, { useState } from 'react';
import { Link ,  useNavigate ,NavLink ,} from 'react-router-dom';
import './Navbar.css';
import logo from '../../images/logo.png'
import { HashLink } from "react-router-hash-link";

function Navbar() {
  
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);


  return (
    <>
      <nav className=' sticky-top navbar'>
        <div className='container'>
        <NavLink to='/'  className='navbar-logo' onClick={closeMobileMenu}>
		       <img src={logo} alt="logo" height={50}/>
        </NavLink>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={`d-flex justify-content-center align-items-center text-center ${click ? 'nav-menu active' : 'nav-menu'}`}>
          <li>
            <HashLink to="/" className='nav-links' onClick={closeMobileMenu}>Accueil</HashLink>
          </li>
		      <li>
            <a href="https://web.ouicare.cm/" className='nav-links' onClick={closeMobileMenu} target="_blank"> OuiCare web </a>
          </li>

          <li>
            <a href="http://dondesang.ouicare.cm/" className='nav-links' onClick={closeMobileMenu} target='_blank'>Don deSang</a>
          </li>
          
          <li >
            <NavLink 
              to='/Fonctionnalites'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Fonctionnalites
            </NavLink>
          </li>
        </ul> 
        </div> 
      </nav>
    </>
  );
}

export default Navbar;
