import React, { useState } from 'react'
import FooterGen from '../Components/footer/FooterGen'
import Header from '../Components/header/Header'
import PhoneInput from 'react-phone-number-input'


function Contactpage() {
    const [valueNom, setValueNom] = useState('')
    const [valuePrenom, setValuePrenom] = useState('')
    const [valueNumb, setValueNumb] = useState('')
    const [valueEmail, setValueEmail] = useState('')
    const [valueComm, setValueComm] = useState('')
    const [value, setValue] = useState('');
    const [submit, setSubmit] = useState(false);
    const [sub, setSub] = useState(false);

    const handleConnect = (e) => {
        e.preventDefault();
        setSubmit(!submit);
        if (submit) {
            setValueNom('');
            setValuePrenom('');
            setValueEmail('');
            setValueComm('');
            setValueNumb('')
        };
    }

    const handleLetter = (e) => {
        e.preventDefault();
        setSub(!sub)
        sub && setValue('')
    }

    return (
        <>
            <Header />
            <header className='m-md-5'>
                <div className="cardH m-md-5">
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="cardH-inner m-5">
                        <div className='m-5' style={{ height: '5rem' }}>
                            <h1>Contactez-nous</h1>
                        </div>
                        {/* <img src={desk} height='350px' /> */}
                    </div>
                </div>
            </header>
            <main className='container p-md-5'>
                <div className="container pt-5 section-heading section-heading--center">
                    <h6 className="__subtitle">Besoin de plus d'information ?</h6>
                    <h2 className="__title"><br />Contactez-nous a travers ce formulaire</h2>
                </div>
                <div className="container  col-md d-flex justify-content-center  _shadow1">
                    <form className="row-md g-3 needs-validation" onSubmit={(e) => handleConnect(e)}>
                        <div className="">
                            <label htmlFor="validationCustom01" className="form-label">Nom</label>
                            <input
                                type="text"
                                className="form-control"
                                value={valueNom}
                                onChange={e => setValueNom(e.target.value)}
                                required />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className="mt-2">
                            <label htmlFor="validationCustom02" className="form-label">Premon</label>
                            <input
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                value={valuePrenom}
                                onChange={e => setValuePrenom(e.target.value)}
                                required />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className="mt-2">
                            <label htmlFor="validationCustom03" className="form-label">Numéro de téléphone</label>
                            <PhoneInput
                                // className="form-control"
                                id="validationCustom03"
                                // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                // required minLength={9} maxLength={18}
                                value={valueNumb}
                                onChange={setValueNumb} />

                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className="mt-2">
                            <label htmlFor="validationCustomUsername" className="form-label">Email</label>
                            <div className="input-group has-validation">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="validationCustomUsername"
                                    aria-describedby="inputGroupPrepend"
                                    value={valueEmail}
                                    onChange={e => setValueEmail(e.target.value)}
                                    // pattern=".+@globex\.com"
                                    required />
                                <div className="invalid-feedback">
                                    Please your email
                                </div>
                            </div>
                        </div>

                        <div className="mt-2">

                            <label htmlFor="validationCustom03" className="form-label">Commentaires</label>
                            <div className="form-floating">
                                <textarea
                                    className="form-control"
                                    placeholder="Leave a comment here"
                                    id="validationCustom03"
                                    value={valueComm}
                                    onChange={e => setValueComm(e.target.value)}
                                    defaultValue={""} />
                            </div>
                            <div className="invalid-feedback">
                                Please provide a valid city.
                            </div>
                        </div>
                        {
                            submit && <div className="alert alert-warning mt-3">
                                <p> Cette fonctionnalite n'est pas encore disponible pour le moment!! </p>
                            </div>
                        }
                        <div className="col-12 text-center  mt-3">
                            <button className="btns btn" type="submit">Envoyer</button>
                        </div>
                    </form>
                </div>
                <br />
                <br />
                <br />
                <div className="section-heading section-heading--center">
                    <h6 className="__subtitle">Preferez-vous être notifier directement par mail ?</h6>
                    <h2 className="__title">Abonnez-vous au Newsletter de OuiCare</h2>
                </div>
                <div className=" col-md d-flex justify-content-center  p-md-5 ">
                    <form className="row-md  needs-validation " noValidate onSubmit={e => handleLetter(e)}>
                        <div className="text-center">
                            <span>NEWSLETTERS</span><br />
                            <span style={{ fontSize: '1.8rem' }}>Nous vous informons régulièrement</span><br />
                            <span>Pour ne rater aucune de nos actualités, souscrivez aux Newsletters pour recevoir de nos courriels.</span>
                        </div>
                        <div className="input-group has-validation mt-3">

                            <input
                                type="email"
                                className="form-control"
                                aria-describedby="inputGroupPrepend"
                                placeholder="Adresse email"
                                value={value}
                                onChange={e => setValue(e.target.value)}
                                required />
                            <div className="invalid-feedback">
                                Please your email.
                            </div>
                        </div>

                        {
                            sub && <div className="alert alert-warning mt-3">
                                <p> Cette fonctionnalite n'est pas encore disponible pour le moment!! </p>
                            </div>
                        }

                        <div className="col-12 text-center mt-3">
                            <button className="btns btn" type="submit">Abonnez-vous</button>
                        </div>
                    </form>
                </div>
            </main>
            <FooterGen />
        </>
    )
}

export default Contactpage