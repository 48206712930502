import React from 'react'
import './Services.css'
import { FaBookMedical,FaHandHoldingMedical } from "react-icons/fa";
import { Link } from 'react-router-dom';
function Services() {
  return (
	<>
		<section id="services" className="section mt-5">
        <div className="container">
          <div className="section-heading section-heading--center">
            <h6 className="__subtitle">Nos Services</h6>
            <h2 className="__title">Des services  qui répondent à vos besoins quotidiens.</h2>
          </div>
          <div className="spacer py-6" />
          <div className="row">
            <div className="col-12">
              {/* start services */}
              <div className="services services--s6">
                <div className="__inner">
                  <div className="row">
                    {/* start services */}
                    <div className="services services--s6">
                      <div className="__inner">
                        <div className="row justify-content-center">
                          {/* start item */}
                          <div className="col-12 col-sm-6 col-xl-3 d-sm-flex">
                            <div className="__item __item--rounded __item--shadow js-tilt" data-tilt-speed={600} data-tilt-max={22} data-tilt-perspective={800}>
                              <div className="mb-5" style={{textAlign: 'center'}}>
                                <i className="fas fa-briefcase-medical" style={{color: '#1065b2', fontSize: '50px', textAlign: 'center'}} />
                              </div>
                              <h4 className="__title" ><a href="" className='nav-link'>Dossier Médical</a></h4>
                              <p>
                                Toutes les informations de santé d'un patient rassemblées dans un dossier <strong>sécurisé</strong> .. Il est accessible partout et en n'importe...                                                 
                                </p></div>
                          </div>

						              <div className="col-12 col-sm-6 col-xl-3 d-sm-flex">
                            <div className="__item __item--rounded __item--shadow js-tilt" data-tilt-speed={600} data-tilt-max={22} data-tilt-perspective={800}>
                              <div className="mb-5" style={{textAlign: 'center'}}>
                                <FaBookMedical style={{color: '#FFA500', fontSize: '50px', textAlign: 'center'}}/>
                              </div>
                              <h4 className="__title"><a href="service2b52.html?id=texA1kaFaW-sZoj" className='nav-link'>Carnet de santé digital</a></h4>
							  <p>Être un bon patient est bien souvent difficile lorsqu’on a beaucoup de carnet de santé à gérer. Avec le carnet numérique OuiCare, cela devient beaucoup plus facile...</p>
							  </div>
                          </div>

                          <div className="col-12 col-sm-6 col-xl-3 d-sm-flex">
                            <div className="__item __item--rounded __item--shadow js-tilt" data-tilt-speed={600} data-tilt-max={22} data-tilt-perspective={800}>
                              <div className="mb-5" style={{textAlign: 'center'}}>
                                <i className="fas fa-mobile-alt" style={{color: '#3aba86', fontSize: '50px', textAlign: 'center'}} />
                              </div>
                              <h4 className="__title"><a href="service00f3.html?id=lJLqCZW5tGOgxbr" className='nav-link'>Téléconsultation</a></h4>
                              <p>Choisissez un praticien disponible en ligne tous les jours de 6 h à minuit. Consultez depuis votre smartphone en téléchargeant...                                                  
                              </p></div>
                          </div>

                          <div className="col-12 col-sm-6 col-xl-3 d-sm-flex">
                            <div className="__item __item--rounded __item--shadow js-tilt" data-tilt-speed={600} data-tilt-max={22} data-tilt-perspective={800}>
                              <div className="mb-5" style={{textAlign: 'center'}}>
                                <FaHandHoldingMedical style={{color: '#726AE4', fontSize: '50px', textAlign: 'center'}}/>
                              </div>
                              <h4 className="__title"><a href="service7438.html?id=m1oBe5Kh3M_AW4l" className='nav-link'>prise de <br/>rendez-vous</a></h4>
                              <p>Quand on a pas envie de passer 45 minutes à 2 heures dans une salle d’attente, prendre un rendez-vous permet de gérer au mieux son emploi de temps. OuiCare ...</p>
							              </div>
                          </div>
                          {/* end item */}
                        </div>
                      </div>
                    </div>
                    {/* end services */}
                  </div>
                </div>
              </div>
              {/* end services */}
              <br />
              <p className="mt-9" style={{textAlign: 'center'}}>
                <Link className="custom-btn custom-btn--medium custom-btn--style-2" to="/Services" id='lirePlus'>Lire plus</Link>
              </p>
            </div>
          </div>
        </div>
    </section>
	</>
  )
}

export default Services