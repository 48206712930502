import React from 'react'
import Header from '../Components/header/Header';
import Footer from '../Components/Footer';
import StartScreen from '../Components/Main/startScreen/StartScreen';
import Features from '../Components/Main/features/Features';
import Services from '../Components/Main/services/Services';
import About from '../Components/Main/AboutScreen/About'
import Newletters from '../Components/Main/Newletters/Newletters';
import Coupure from '../Components/Main/Coupures/Coupure';
import Testimony from '../Components/Main/Testimony/Testimony'
import Sponsor from '../Components/Main/Sponsors/Sponsor';

function Homepage() {
  return (
	<>
      <Header/>
      <StartScreen/>
      <About/>
      <Services/>
      <Features/>
      <Testimony/>
      <Coupure/>
      <Sponsor/>
      <Newletters />
      <Footer />
	</>
  )
}

export default Homepage