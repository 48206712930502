import React, { useState } from 'react'



function Items({ content }) {

	const [flag, setFlag] = useState(false);

	const handelFlag=()=>{
		return(
			!flag? setFlag(true):setFlag(false)
		)
	}

	return (
		<>
		<div className="accordion m-3">
			<div className="accordion-item">
				<h2 className="accordion-header">
					<button className="accordion-button" onClick={handelFlag} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
					{content.question}
					</button>
				</h2>
				<div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
					{
						flag && <div className="accordion-body">{content.reponse}</div>
					}
				</div>
			</div>
		</div>
		</>
	)
}

export default Items