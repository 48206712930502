import React from 'react'
import Header from '../Components/header/Header'
import Footer from "../Components/Footer";
import Fonction from '../Components/fonctionnalites/Fonction';
import FooterGen from '../Components/footer/FooterGen';

function Fonctionpage() {
  return (
	<>
		<Header/>
		<Fonction/>
		<FooterGen/>
	</>
  )
}

export default Fonctionpage