import React from 'react'
import imgPress from '../../../../images/journal/covid.png'

function Presse01() {
  return (
	<>
		<div className="d-flex justify-content-center">
			<img src={imgPress} alt="Presse Covid"/>
		</div>
	</>
  )
}

export default Presse01