import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import './Coupure.css'
import ItemsCoupure from './ItemsCoupure';
import DataCoupure from './DataCoupure';



function Coupure() {

	const [current, setCurrent] = useState(0);
	const length = DataCoupure.length;
	
	const nextSlide = () => {
		setCurrent(current === length - 1 ? 0 : current + 1);
	};

	const prevSlide = () => {
		setCurrent(current === 0 ? length - 1 : current - 1);
	};

	if (!Array.isArray(DataCoupure) || DataCoupure.length <= 0) {
		return null;
	}

	return (
		<>
			<div className="section-heading mt-5 mb-3 text-center">
				<h6 className="__subtitle">Comme  apparu dans :</h6>
			</div>

			<section className="page-content">
				{DataCoupure.map((item) => (
					<ItemsCoupure key={item.id} item={item} className='no-phone' />
				))}
			</section>
			<section className="sliders mb-5">

				<FaArrowAltCircleLeft className='left-arrow' onClick={prevSlide} />
				<FaArrowAltCircleRight className='right-arrow' onClick={nextSlide} />				

				{DataCoupure.map((slide, index) => {

					return (
					
						<div
							className={index === current ? 'slide active' : 'slide'}
							key={index}
							id='phone'
						>
							{index === current && (
								<ItemsCoupure key={index} item={slide} className='image'/>
							)}
						</div>
					);
				})}

			</section>
		</>
	)
}

export default Coupure