import React from 'react'
import { Link ,NavLink,Navigate} from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
import '../FooterStyle.css'

function FooterGen() {
  return (
	<div>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="footer-col">
              <h4>Liens utiles</h4>
              <ul>
                <li>
                {/* <Navigate to="/dashboard" replace={true} /> */}
                  <NavLink to="/">Accueil</NavLink>
                </li>
                <li>
                  <NavLink to="/About">A propos</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/Services">Nos Services</NavLink>
                </li> */}
                <li>
                  <NavLink to="/Fonctionnalites">Fonctionnalités</NavLink>
                </li>
				<li>
                  <NavLink to="/Contact">Contactez-nous</NavLink>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Mentions légales</h4>
              <ul>
                <li>
                  <a href="#">Politique de confidentialité</a>
                </li>
                <li>
                  <a href="#">Conditions générale d'utilisation</a>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Contact</h4>
              <ul>
                <li>
                  <a href="#">
                    Adress: 1249 Rue Charley Eyoum Ebelle <br /> B.P: 598 Douala
                  </a>
                </li>
                <li>
                  <a href="#">Tel: +237 694-271-572 / +237 696-284-641</a>
                </li>
                <li>
                  <a href="#">Email: infos@ouicare.cm</a>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Suivez-nous</h4>
              <div className="social-links">
                <a href="https://www.facebook.com/OuiCare.health">
                  <i className="fab fa-facebook-f" />
                </a>
                <a href="https://twitter.com/OuiCare_health">
                  <i className="fab fa-twitter" />
                </a>
                <a href="https://www.instagram.com/OuiCare.health">
                  <i className="fab fa-instagram" />
                </a>
                <a href="https://www.linkedin.com/company/ouicare-health/ ">
                  <i className="fab fa-linkedin-in" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default FooterGen