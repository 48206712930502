import React from 'react'
import Faq from "../Components/faq/Faq";
import Header from '../Components/header/Header'
import Footer from "../Components/Footer";

function Faqpage() {
  return (
	<>
		<Header/>
		<Faq/>
		<Footer/>
	</>
  )
}

export default Faqpage