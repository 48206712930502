import React from 'react'
import './Fonction.css'
import art from '../../images/Fonction/article.png'
import desk from '../../images/Fonction/desktop.png'
import disc from '../../images/Fonction/discuss.png'
import lock from '../../images/Fonction/lock.png'
import phn from '../../images/Fonction/phone.png'
import phn2 from '../../images/Fonction/phon2.png'
import rdv from '../../images/Fonction/rdv.png'

function Fonction() {
	return (
		<>
			<header className='m-md-5'>
				<div className="cardH m-md-5">
					<div className="circle"></div>
					<div className="circle"></div>
					<div className="cardH-inner m-5">
						<div className='m-5' style={{height: '5rem'}}>
						<h1>Fonctionnalites</h1>
						</div>
						{/* <img src={desk} height='350px' /> */}
					</div>
				</div>
			</header>
			<main className=''>
				<div className='row m-md-3' >
					<div className='col-md-6'>
						<div className="cardC"  style={{background:'linear-gradient(to top, #0a3394, #4286f4);'}}>
							<div className="cardC-image">
								<img src={disc} />
							</div>
							<div className="cardC-description">
								<p className="text-title">Échange de messages fiable avec des médecins</p>
								<p className="textC-body">
									Avec OuiCare, profitez d'une communication rapide, simple avec des médecins du Cameroun et du monde entier et ceci gratuitement. Vous pouvez contactez n'importe quel médecin à tout moment et recevoir des conseils sur la santé ou demander un suivi médical.
								</p>
							</div>
						</div>
					</div>
					<div className='col-md-6'>
						<div className="cardC">
							<div className="cardC-image">
								<img src={phn} width='45%' />
							</div>
							<div className="cardC-description">
								<p className="text-title">Conservez votre carnet de santé numérique</p>
								<p className="textC-body">
								Être un bon patient est bien souvent difficile lorsqu’on a beaucoup de carnet de santé à gérer. Avec le carnet numérique OuiCare, cela devient beaucoup plus facile.le carnet de santé OuiCare conserve l'historique de toutes vos consultations médicales mais également vos prises de médicaments, vous évitant ainsi de transporter vos carnets physiques lors de vos déplacements par exemple.
								</p>
							</div>
						</div>
					</div>
				</div>


				<div className='row m-md-3'>
					<div className='col-md-6'>
						<div className="cardC">
							<div className="cardC-image">
								<img src={art} />
							</div>
							<div className="cardC-description">
								<p className="text-title">Lire les articles et discuter dans les forums de santé</p>
								<p className="textC-body">
									Restez en contact avec des personnes pour discuter de la santé, suivant des thématiques précises. Avec les discussions de forum, vous pouvez partager des messages, avec d 'autres personnes et poser des questions sur la santé. Vous pouvez aussi lire des articles de santé pour vous tenir au courant de l'actualité médicale .
								</p>
							</div>
						</div>
					</div>
					<div className='col-md-6'>
						<div className="cardC">
							<div className="cardC-image">
								<img src={rdv} height='100%' width='50%' />
							</div>
							<div className="cardC-description">
								<p className="text-title">Prenez un rendez vous avec médécin</p>
								<p className="textC-body">
								Quand on a pas envie de passer 45 minutes à 2 heures dans une salle d’attente, prendre un rendez-vous permet de gérer au mieux son emploi de temps. OuiCare vous donnez également la possibilité de selectionner un médecin vivant près de chez vous , très utile lorsqu'on est à la recherche d'un spécialiste.
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className='row m-md-3 '>
				<div className='col-md-6'>
						<div className="cardC">
							<div className="cardC-image">
								<img src={phn2} width='50%'/>
							</div>
							<div className="cardC-description">
								<p className="text-title">OuiCare Symptom Checker</p>
								<p className="textC-body">
								Ouicare Symptom Checker (OSC) est un système destiné à vous éclairer sur les causes possibles de vos symptômes et à vous aider dans votre orientation médicale.Vous pouvez également utiliser le dictionnaire médical pour vous informer sur une pathologie.
								<strong>
								Les réusltats fournies par OSC ne constituent en aucun cas un diagnostic et ne peuvent pas remplaer une consultation médicale.
								</strong>
								</p>
							</div>
						</div>
				</div>
					<div className='col-md-6'>
						<div className="cardC">
							<div className="cardC-image">
								{/* <img src={art} height='100%'/> */}
							</div>
							<div className="cardC-description">
								<p className="text-title">Continuer sur OuiCare Web</p>
								<p className="textC-body">
								Avec OuiCare sur le web et desktop, vous pouvez synchroniser facilement toutes vos actions dans votre ordinateur pour que vous puissiez suivre votre dossier medical sur n'importe quel appareil qui vous convient le mieux. Téléchargez l'application pour le bureau ou visitez ouicare.cm pour commencer.
								</p>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	)
}

export default Fonction