import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import './ItemsCoupure.css'

function ItemsCoupure({ item }) {
	return (
		<>
			<div className="card">
				<div className="card-img" style={{backgroundImage: `url('${item.image}')`}}></div>
				<div className="card-info">
					<p className="text-title">{item.title}</p>
					<p className="text-b" style={{color:'#fff'}}>{item.text}</p>
					<NavLink to={item.to} target='_blank'>
						<button className="btn">Voir l'article</button>
					</NavLink>
				</div>
			</div>
		</>
	)
}

export default ItemsCoupure