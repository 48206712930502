/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import './About.css';
import ReactPlayer from 'react-player';
import video from "../../../images/PrésentationOuiCare.mp4";
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <section id="about" className="section section--no-pb">
      <div className="container">
        <div className="row flex-md-row-reverse justify-content-sm-center align-items-md-center">
          <div className="col-12 col-md-6">
            <div className="section-heading">
              <h6 className="__subtitle">A propos</h6>
              <h2 className="__title">Qui sommes-nous ? </h2>
            </div>
            <div className="spacer py-4" />
            <div>
              <p style={{ textAlign: 'justify !important' }}></p>
              <p>
                En effectuant des recherches concernant les historiques de santé, nous avons découvert que la majeure partie des décès en Afrique Subsaharienne étaient due à l’absence d’historiques médicaux des patients ce qui entrave grandement leur prise en charge
                OuiCare est une plateforme sanitaire simple et évolutive qui permet aux utilisateurs de sauvegarder en toute sécurité leurs données médicales, d’y avoir accès à tout moment et enfin de prendre des rendez-vous avec des professionnels de la santé pour des consultations
              </p>
              <Link className="custom-btn custom-btn--medium custom-btn--style-2" to='/About' id='lirePlus'>Lire plus</Link>
            </div>
          </div>
          <div className="spacer py-4 d-md-none" />
          <div className="col-12 col-md-6  text-center text-md-left">
            <figure className="image-container js-tilt" data-tilt-speed={600} data-tilt-max={15} data-tilt-perspective={700}>
              <ReactPlayer className="img-fluid lazy" width={581} height={424} url={video} playing={false} volume={true} stopOnUnmount={false} pip={true} controls={true} data-srcset="/images/page/6j7vowu_yff-G_C-Awesome_Things_About_TechLand.png 1x, /images/page/6j7vowu_yff-G_C-Awesome_Things_About_TechLand.png 2x" alt="demo" />
            </figure>
          </div>
        </div>
        {/*<div class="spacer py-9"></div>*/}
      </div>
    </section>
  );
};

export default About;