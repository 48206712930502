const Reponses = [
	{
		id:1,
		question:'Velit cupidatat pariatur temp Non et irure occaecat labore voluptate nulla eu.?',
		reponse:'reponse of the question1'
	},
	{
		id:2,
		question:'Velit cupidatat pariatur temp Mollit occaecat elit dolore veniam aute minim velit.?',
		reponse:'reponse of the question2'
	},
	{
		id:3,
		question:'Velit cupidatat pariatur temp Aute consectetur voluptate in incididunt magna sint.?',
		reponse:'reponse of the question'
	},
	{
		id:4,
		question:'Velit cupidatat pariatur temp Occaecat labore commodo irure exercitation.?',
		reponse:'reponse of the question'
	},
	{
		id:5,
		question:'Velit cupidatat pariatur temp Occaecat labore commodo irure exercitation.?',
		reponse:'reponse of the question'
	},
	{
		id:6,
		question:'Velit cupidatat pariatur temp Occaecat labore commodo irure exercitation.?',
		reponse:'reponse of the question'
	},
	{
		id:7,
		question:'Velit cupidatat pariatur temp Occaecat labore commodo irure exercitation.?',
		reponse:'reponse of the question'
	},
]

export default Reponses