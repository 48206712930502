import React from 'react'
import imgPress from '../../../../images/journal/grand_angle2022.png'

function Presse07() {
  return (
	<>
		<div className="d-flex justify-content-center">
			<img src={imgPress} alt="Presse Focus"/>
		</div>
	</>
  )
}

export default Presse07