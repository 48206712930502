
/**
 * @author:tdms410
 */
import React, { useState } from 'react'
import './faq.css'
import Items from './Items'
import Reponses from './reponses'





function Faq() {

	const [data, setData] = useState(Reponses)
	console.log(data)

	return (
		<>
			<div className='contain '>
				<div className='card'>
					<div className='row m-md-5 mt-5 '>
						<h1>
							Besoin de précisions ?
						</h1>
						<h3 className='textFaq' >posez-nous vos questions</h3>
					</div>
				</div>
			</div>
			<main className='container mt-2 mb-5'>
				<div className='row mt-5'>
					<h3 className='text-center'>Questions fréquentes</h3>
				</div>
				<div className="p-3">
					{data.map((i) => (
						<Items key={i.id} content={i} />
					))}
				</div>
			</main>
		</>
	)
}

export default Faq