/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { HashLink } from "react-router-hash-link";
import "./FooterStyle.css";
const Footer = () => {
  return (
    <div>
      <title>Footer Design</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="stylesheet" href="css/style.css" />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"
      />
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="footer-col">
              <h4>Liens utiles</h4>
              <ul>
                <li>
                  <HashLink to="#">Accueil</HashLink>
                </li>
                <li>
                  <HashLink to="#about">A propos</HashLink>
                </li>
                <li>
                  <HashLink to="#services">Nos Services</HashLink>
                </li>
                <li>
                  <HashLink to="#features">Fonctionnalités</HashLink>
                </li>
                <li>
                  <HashLink to="#newletters">Contactez nous</HashLink>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Mentions légales</h4>
              <ul>
                <li>
                  <a href="#">Politique de confidentialité</a>
                </li>
                <li>
                  <a href="#">Conditions générale d'utilisation</a>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Contact</h4>
              <ul>
                <li>
                  <a href="#">
                    Adress: 1249 Rue Charley Eyoum Ebelle <br /> B.P: 598 Douala
                  </a>
                </li>
                <li>
                  <a href="#">Tel: +237 694-271-572 / +237 696-284-641</a>
                </li>
                <li>
                  <a href="#">Email: infos@ouicare.cm</a>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Suivez-nous</h4>
              <div className="social-links">
                <a href="https://www.facebook.com/OuiCare.health">
                  <i className="fab fa-facebook-f" />
                </a>
                <a href="https://twitter.com/OuiCare_health">
                  <i className="fab fa-twitter" />
                </a>
                <a href="https://www.instagram.com/OuiCare.health">
                  <i className="fab fa-instagram" />
                </a>
                <a href="https://www.linkedin.com/company/ouicare-health/ ">
                  <i className="fab fa-linkedin-in" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
