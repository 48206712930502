import im from '../../../images/testimony/Crescence.jpg'
import im1 from '../../../images/testimony/duplexe.png'
import im2 from '../../../images/testimony/Michel.png'
import im3 from '../../../images/testimony/Moukam.jpg'
import im4 from '../../../images/testimony/Nelly.png'
import im5 from '../../../images/testimony/profil.png'

const dataTesti =[
	{
		id:0,
		name:'Crescence Élodie',
		message:'Simple et pratique pour avoir ses paramètres médicaux. Surtout pour retenir son groupe sanguin..',
		metier:'Présidente, Fondatrice WETECH ',
		img:im
	},
	{
		id:1,
		name:'Dr. kenfack duplexe',
		message:"OuiCare très utile car lorsqu'un patient me contacte à distance Je peux le prendre en charge afin de déterminer ceux qui peuvent arriver dans le centre de santé",
		metier:'Médecin',
		img:im1
	},
	{
		id:2,
		name:'Michel tchomnou',
		message:"Excellent, depuis longtemps qu'on attend ce genre d'initiative",
		metier:'--',
		img:im2
	},
	{
		id:3,
		name:'Dr. Steve Moukam',
		message:"Le point positif sur votre plateforme est le côté informationnel et informatisé de la chose. Il facilite réellement le travail du médecin (... )",
		metier:'Médecin',
		img:im3
	},
	{
		id:4,
		name:'Nelly Chatue Diop',
		message:"Oh Super projet' super équipe' La Population a besoin de ce type de solutions",
		metier:'Entrepreneure',
		img:im4
	},
	
	{
		id:5,
		name:'Anonyme',
		message:"J'ai un membre de ma famille qui utilise cet appli depuis plusieurs mois déjà pour sa famille, et c'est devenu pour elle comme whatsapper",
		metier:'--',
		img:im5
	}
]

export default dataTesti;