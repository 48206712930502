import React from 'react'
import { Link } from 'react-router-dom'
import '../services/Services.css'
import './Features.css'
import back from '../../../images/base.png'
import {AiOutlineCheckCircle } from "react-icons/ai";

function Features() {
  return (
	<>
		<section id="features" className="section pb-3" style={{backgroundImage:`url(${back})`}}>
        <div  className="container pt-3 pb-3" id='textStartSmol'>
          <div className="row align-items-md-center" >
            <div className="col-12 col-lg-6 pt-3" >
              <div className="section-heading">
                <h6 className="__subtitle">Fonctionnalités</h6>
                <h2 className="__title">Les technologies au service de l'humain</h2>
              </div>
                  <div className="spacer py-1" />
                      <div style={{textAlign: 'justify !important'}}>
                        Nous implémentons des fonctionnalités adaptées à tout type d’utilisateur.
                        Partant de la géolocalisation des centres de santé et de pharmacie les plus proches, la téléconsultation, la prise de rendez-vous, à l’e-prescription, vous découvrirez plusieurs fonctionnalités qui répondent au besoin de chacun (médecin, laborantin, pharmacien, patient, etc.).
                        Toute une large gamme de fonctionnalités qui évoluent en fonction de l’environnement.
                        <ul className="check-list my-md-2 ">
                          <li><AiOutlineCheckCircle style={{color: 'rgb(255,165,0)'}}/> Échange de messages fiable avec des médecins</li>
                          <li><AiOutlineCheckCircle style={{color: 'rgb(255,165,0)'}}/> Conservez votre carnet de santé numérique</li>
                          <li><AiOutlineCheckCircle style={{color: 'rgb(255,165,0)'}}/> Prenez un rendez vous avec médécin</li>
                          <li><AiOutlineCheckCircle style={{color: 'rgb(255,165,0)'}}/> Lire les articles et discuter dans les forums de santé</li>
                          <li><AiOutlineCheckCircle style={{color: 'rgb(255,165,0)'}}/> Continuer sur OuiCare Web</li>
                          <li><AiOutlineCheckCircle style={{color: 'rgb(255,165,0)'}}/> OuiCare Symptom Checker</li>
                      </ul>
                        <p className="">
                        {/* <Link
                          to='/Fonctionnalites'
                          className="custom-btn custom-btn--medium custom-btn--style-2"
                          id='lirePlus'
                        >
                          Lire plus
                        </Link> */}
                        </p>
                      </div>
                </div>
          </div>
        </div>
    </section>
	</>
  );
}

export default Features