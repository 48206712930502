import React from 'react';
import './App.css';
import {
	BrowserRouter as Router,Routes,	Route,	Link} from 'react-router-dom';

import Homepage from './pages/Homepage';
import Faqpage from './pages/Faqpage';
import Fonctionpage from './pages/Fonctionpage';
import Presse01 from './Components/Main/Coupures/ImageCompo/Presse01';
import Presse02 from './Components/Main/Coupures/ImageCompo/Presse02';
import Presse03 from './Components/Main/Coupures/ImageCompo/Presse03';
import Presse04 from './Components/Main/Coupures/ImageCompo/Presse04';
import Presse05 from './Components/Main/Coupures/ImageCompo/Presse05';
import Presse06 from './Components/Main/Coupures/ImageCompo/Presse06';
import Presse07 from './Components/Main/Coupures/ImageCompo/Presse07';
import Aboutpage from './pages/Aboutpage';
import Contactpage from './pages/Contactpage';
import ServicesPage from './pages/ServicesPage';
 









function App() {
  return (
    <Routes>
       <Route path='/' element={<Homepage/>}></Route>
       <Route path='Services' element={<ServicesPage/>}></Route>
       <Route path='Contact' element={<Contactpage/>}></Route>
       <Route path='About' element={<Aboutpage/>}></Route>
       <Route path='Faq' element={<Faqpage/>}></Route>
       <Route path='Fonctionnalites' element={<Fonctionpage/>}></Route>
       <Route path='Presse01' element={<Presse01/>}></Route>
       <Route path='Presse02' element={<Presse02/>}></Route>
       <Route path='Presse03' element={<Presse03/>}></Route>
       <Route path='Presse04' element={<Presse04/>}></Route>
       <Route path='Presse05' element={<Presse05/>}></Route>
       <Route path='Presse06' element={<Presse06/>}></Route>
       <Route path='Presse07' element={<Presse07/>}></Route>
    </Routes>
    
  );
}

export default App;
