import img1 from '../../../images/journal/Cameroon Tribune.png'
import img2 from '../../../images/journal/covid.png'
import img3 from '../../../images/journal/economie.png'
import img4 from '../../../images/journal/focus.png'
import img5 from '../../../images/journal/fond.png'
import img6 from '../../../images/journal/grand_angle.png'
import img7 from '../../../images/journal/grand_angle2022.png'
import img8 from '../../../images/journal/lien.png'
import img9 from '../../../images/journal/lien1.png'
import img10 from '../../../images/journal/lien2.png'
import img11 from '../../../images/journal/lien3.png'
const DataCoupure =[
	{
		id:0,
		title:"Journal Camroun Tribune",
		text:"A aussi parlé de OUI-CARE",
		to:"/Presse04",
		at:null,
		image:img1
	},
	{
		id:1,
		title:"Journal le Focus",
		text:"Edition N°01955 Paru le jeudi 02juillet 2020",
		to:"/Presse01",
		at:null,
		image:img2
	},
	{
		id:2,
		title:"Journal le Focus",
		text:"Edition N° 01895 Paru le jeudi 09 Mars 2020",
		to:"/Presse02",
		at:null,
		image:img4
	},
	{
		id:3,
		title:"Journal L'Economie",
		text:"Edition N° 009 Paru en Octobre 2021",
		to:"/Presse03",
		at:null,
		image:img3
	},
	{
		id:4,
		title:"Journal Grand'Angle",
		text:"Edition N° 074 Paru le Lundi 13 Juillet 2020",
		to:"/Presse05",
		at:null,
		image:img6
	},
	{
		id:5,
		title:"Journal L'economie",
		text:"Edition N° 01959 Paru le jeudi 09 Juillet 2020",
		to:"/Presse06",
		at:null,
		image:img5
	},
	{
		id:6,
		title:"Journal Grand'Angle",
		text:"Edition N° 145 Paru le Lundi 20 Décembre 2021",
		to:"/Presse07",
		at:null,
		image:img7
	},
	// {
	// 	id:8,
	// 	title:"startupbrics.com",
	// 	text:"#Covid-19 Series : Au Cameroun, OuiCare conseille les citoyens face au Coronavirus",
	// 	to:null,
	// 	at:"//www.google.com/url?sa=t&source=web&rct=j&url=https://startupbrics.com/covid-19-series-cameroun-ouicare-citoyens-coronavirus/&ved=2ahUKEwjFt7Dq8Jr4AhX2Q_EDHbKxDNgQFnoECAwQAQ&usg=AOvVaw3AZsPUp_m8ygaNaWW-l9Uw",
	// 	image:img9
	// },
	// {
	// 	id:9,
	// 	title:"startupbrics.com",
	// 	text:"OuiCare :Une application pour rendre le patient camerounais autonome et averti",
	// 	to:null,
	// 	a:"https://www.google.com/url?sa=t&source=web&rct=j&url=https://startupbrics.com/ouicare-numerisation-donnees-medicales-cameroun/&ved=2ahUKEwiv4cOZ6Zr4AhWQ_IUKHT0bBVwQFnoECAoQAQ&usg=AOvVaw1xxEa4zxFzxNZ03XLMR57E",
	// 	image:img8
	// },
	// {
	// 	id:10,
	// 	title:"agenceecofin.com",
	// 	text:"Ecofin Entrepreneur l’application camerounaise OuiCare décroche le Prix Orange... ",
	// 	to:null,
	// 	a:'https://www.google.com/url?sa=t&source=web&rct=j&url=https://www.agenceecofin.com/entreprendre/1211-93127-l-application-camerounaise-ouicare-decroche-le-prix-orange-de-l-entrepreneuriat-social-en-afrique-et-au-moyen-orient&ved=2ahUKEwiv4…',
	// 	image:img10
	// },
	// {
	// 	id:11,
	// 	title:"digitalbusiness.africa",
	// 	text:"Le carnet de santé électronique OuiCare, vainqueur du grand prix international POESAM 2021, décroche 16...",
	// 	to:null,
	// 	a:'https://www.google.com/url?sa=t&source=web&rct=j&url=https://www.digitalbusiness.africa/cameroun-le-carnet-de-sante-electronique-ouicare-vainqueur-du-grand-prix-international-poesam-2021-decroche-16-millions-de-f-cfa/%3Famp&ved=2ahUKEwiv4cOZ6Zr4AhWQ_IUKHT0bBVwQFnoECBwQAQ&usg=AOvVaw0u6Bs3yoyvF5Y4T6nIoDvD',
	// 	image:img11
	// },
	
]

export default DataCoupure;