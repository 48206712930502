import React from 'react'
import './StartScreen.css'
import img1 from '../../../images/shape_1.png'
import img2 from '../../../images/shape_2.png'
import logoGo from '../../../images/logoGoogle.png'


function StartScreen() {
  return (
    <>
      <div id="start-screen" className="start-screen start-screen--full-height start-screen--style-1">
        <div className="start-screen__shapes  d-none d-lg-block">
          <img className="img-shape d-none d-xl-block" src={img1} alt="demo" />
          <img className="img-shape" src={img2} alt="demo" />
        </div>
        <div className="start-screen__content-container">
          <div className="start-screen__content__item align-items-center">
            <div className="container" id='textStart' >
              <div className="row" >
                <div className="col-12 col-md-10 col-lg-6" >
                  <div className="d-none d-md-block">
                    <div className="__site-name" />
                  </div>
                  <h1><span>Votre univers médical en main </span></h1>
                  <p style={{ textAlign: 'justify !important' }}>
                    <b>OuiCare</b> est une plateforme sanitaire simple et évolutive qui permet aux utilisateurs de sauvegarder en toute sécurité leurs données médicales, d’y avoir accès à tout moment et enfin de prendre des rendez-vous avec des professionnels de la santé pour des consultations
                  </p>
                  <p style={{ textAlign: 'justify !important' }}>
                    Avec <b>OuiCare,</b> profitez d'une communication rapide, simple avec des médecins du Cameroun et du monde entier et ceci gratuitement.
                  </p>
                  {/* <div className="spacer py-2 py-sm-4" /> */}
                  <div className="d-sm-table">
                    <div className="d-sm-table-cell  pb-5 pb-sm-0 pr-sm-8 pr-md-10">
                      <a className="custom-btn custom-btn--big custom-btn--style-1" href="https://play.google.com/store/apps/details?id=com.patient.OuiCare"><img src={logoGo} width={200} /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StartScreen