import React from 'react'
import FooterGen from '../Components/footer/FooterGen'
import Header from '../Components/header/Header'
import about from '../images/About.png'

function Aboutpage() {
  return (
	<>
	 <Header/>
	 <header className='m-md-5'>
				<div className="cardH m-md-5">
					<div className="circle"></div>
					<div className="circle"></div>
					<div className="cardH-inner m-5">
						<div className='m-5' style={{height: '5rem'}}>
						<h1>A propos</h1>
						</div>
						{/* <img src={desk} height='350px' /> */}
					</div>
				</div>
			</header>
			<main>
			<div className='row m-md-3' >
					<div className='col-md'>
						<div className="cardC"  style={{background:'linear-gradient(to top, #0a3394, #4286f4);'}}>
							<div className="cardC-image">
								<img src={about} width='70%'/>
							</div>
							<div className="cardC-description">
								<p className="text-title">Qui sommes-nous ?</p>
								<p className="textC-body " style={{fontSize:'20px'}}>
								En effectuant des recherches concernant les historiques de santé, nous avons découvert que la majeure partie des décès en Afrique Subsaharienne étaient due à l’absence d’historiques médicaux des patients ce qui entrave grandement leur prise en charge
                OuiCare est une plateforme sanitaire simple et évolutive qui permet aux utilisateurs de sauvegarder en toute sécurité leurs données médicales, d’y avoir accès à tout moment et enfin de prendre des rendez-vous avec des professionnels de la santé pour des consultations
								</p>
							</div>
						</div>
					</div>
				</div>
			</main>
	 <FooterGen/>
	</>
  )
}

export default Aboutpage