import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import "./Newletters.css";
import img from '../../../images/Newsletter-cuate.png'

// npm i @emailjs/browser
// npm i react-phone-number-input

const Contact = () => {

    const [valueNom, setValueNom] = useState('')
    const [valuePrenom, setValuePrenom] = useState('')
    const [valueNumb, setValueNumb] = useState('')
    const [valueEmail, setValueEmail] = useState('')
    const [valueComm, setValueComm] = useState('')
    const [value, setValue] = useState('');
    const [submit, setSubmit] = useState(false);
    const [sub, setSub] = useState(false);

    const handleConnect=(e)=>{
        e.preventDefault();
        setSubmit(!submit);
        if (submit) {
            setValueNom('');
            setValuePrenom('');
            setValueEmail('');
            setValueComm('');
            setValueNumb('')};
    }

    const handleLetter=(e)=>{
        e.preventDefault();
        setSub(!sub)
        sub && setValue('')
    }



    return (
        <section id="newletters" className="section section--no-pb mb-3 container">
            <div className="section-heading mt-5 mb-3 text-center">
                <h6 className="__subtitle">Newsletters & contacter nous :</h6>
            </div>
            <div className="  row">

                <div className=" col-md-4 d-flex justify-content-center pt-5 _shadow1">
                    <form className="row-md g-3 needs-validation" onSubmit={(e)=>handleConnect(e)}>
                        <div className="">
                            <label htmlFor="validationCustom01" className="form-label">Nom</label>
                            <input 
                            type="text" 
                            className="form-control" 
                            value={valueNom}
                            onChange={e=>setValueNom(e.target.value)}
                            required />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className="mt-2">
                            <label htmlFor="validationCustom02" className="form-label">Premon</label>
                            <input 
                            type="text" 
                            className="form-control" 
                            id="validationCustom02" 
                            value={valuePrenom}
                            onChange={e=>setValuePrenom(e.target.value)}
                            required />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className="mt-2">
                            <label htmlFor="validationCustom03" className="form-label">Numéro de téléphone</label>
                            <PhoneInput
                                // className="form-control"
                                id="validationCustom03"
                                // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                // required minLength={9} maxLength={18}
                                value={valueNumb}
                                onChange={setValueNumb} />

                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className="mt-2">
                            <label htmlFor="validationCustomUsername" className="form-label">Email</label>
                            <div className="input-group has-validation">
                                <input 
                                type="email" 
                                className="form-control" 
                                id="validationCustomUsername" 
                                aria-describedby="inputGroupPrepend" 
                                value={valueEmail}
                                onChange={e=>setValueEmail(e.target.value)}
                                // pattern=".+@globex\.com"
                                 required />
                                <div className="invalid-feedback">
                                    Please your email
                                </div>
                            </div>
                        </div>

                        <div className="mt-2">

                            <label htmlFor="validationCustom03" className="form-label">Commentaires</label>
                            <div className="form-floating">
                                <textarea 
                                className="form-control"
                                 placeholder="Leave a comment here" 
                                 id="validationCustom03" 
                                 value={valueComm}
                                onChange={e=>setValueComm(e.target.value)}
                                 defaultValue={""} />
                            </div>
                            <div className="invalid-feedback">
                                Please provide a valid city.
                            </div>
                        </div>
                        {
                            submit && <div className="alert alert-warning mt-3">
                                <p> Cette fonctionnalite n'est pas encore disponible pour le moment!! </p>
                            </div>
                        }
                        <div className="col-12 text-center  mt-3">
                            <button className="btns btn" type="submit">Envoyer</button>
                        </div>
                    </form>
                </div>

                <div className=" image col-md-4 image">
                    <img className="" src={img} width='100%' />
                </div>

                <div className="col-md-4  d-flex justify-content-center  pt-5 _shadow2">
                    <form className="row-md  needs-validation " noValidate onSubmit={e=> handleLetter(e)}>
                        <div className="text-center">
                            <span>NEWSLETTERS</span><br />
                            <span style={{fontSize: '1.8rem'}}>Nous vous informons régulièrement</span><br />
                            <span>Pour ne rater aucune de nos actualités, souscrivez aux Newsletters pour recevoir de nos courriels.</span>
                        </div>
                        <div className="input-group has-validation mt-3">
                            <input 
                            type="email" 
                            className="form-control" 
                            aria-describedby="inputGroupPrepend" 
                            placeholder="Adresse email" 
                            value={value}
                            onChange={e=>setValue(e.target.value)}
                            required />
                            <div className="invalid-feedback">
                                Please your email.
                            </div>
                        </div>

                        {
                            sub && <div className="alert alert-warning mt-3">
                                <p> Cette fonctionnalite n'est pas encore disponible pour le moment!! </p>
                            </div>
                        }

                        <div className="col-12 text-center mt-3">
                            <button className="btns btn" type="submit">Abonnez-vous</button>
                        </div>
                    </form>
                </div>

            </div>
        </section>
    );
};

export default Contact;

