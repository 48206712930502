import React from 'react'
import FooterGen from '../Components/footer/FooterGen'
import Header from '../Components/header/Header'

function ServicesPage() {
  return (
	<>
    <Header/>
	 <header className='m-md-5'>
				<div className="cardH m-md-5">
					<div className="circle"></div>
					<div className="circle"></div>
					<div className="cardH-inner m-5">
						<div className='m-5' style={{height: '5rem'}}>
						<h1> Nos Services</h1>
						</div>
						{/* <img src={desk} height='350px' /> */}
					</div>
				</div>
			</header>
			<main>
			<div className='row m-md-3' >
					<div className='col-md-6'>
						<div className="cardC"  style={{background:'linear-gradient(to top, #0a3394, #4286f4);'}}>
							<div className="cardC-image">
								<img  />
							</div>
							<div className="cardC-description">
								<p className="text-title">Dossier Médical</p>
								<p className="textC-body">
                                Toutes les informations de santé d'un patient rassemblées dans un dossier <strong>sécurisé</strong> .. Il est accessible partout et en n'importe...  
								Certaines de vos données médicales les plus personnelles sont partagées sur OuiCare, c'est pourquoi nous assurons un chiffrement très sophistiqué dans les dernières versions de notre application. Lorsqu'ils sont chiffrés , vos messages et données médicales sont protégés afin que seul(e)s vous et la personne avec qui vous communiquez(en l'occurence les médécins) puissiez les lire ou les consulter .                                               
								</p>
							</div>
						</div>
					</div>
					<div className='col-md-6'>
						<div className="cardC">
							<div className="cardC-image">
								<img  width='45%' />
							</div>
							<div className="cardC-description">
								<p className="text-title">Carnet de santé digital</p>
								<p className="textC-body">
								Être un bon patient est bien souvent difficile lorsqu’on a beaucoup de carnet de santé à gérer. Avec le carnet numérique OuiCare, cela devient beaucoup plus facile.le carnet de santé OuiCare conserve l'historique de toutes vos consultations médicales mais également vos prises de médicaments, vous évitant ainsi de transporter vos carnets physiques lors de vos déplacements par exemple.
								</p>
							</div>
						</div>
					</div>
				</div>


				<div className='row m-md-3'>
					<div className='col-md-6'>
						<div className="cardC">
							<div className="cardC-image">
								<img  />
							</div>
							<div className="cardC-description">
								<p className="text-title">Lire les articles et discuter dans les forums de santé</p>
								<p className="textC-body">
									Restez en contact avec des personnes pour discuter de la santé, suivant des thématiques précises. Avec les discussions de forum, vous pouvez partager des messages, avec d 'autres personnes et poser des questions sur la santé. Vous pouvez aussi lire des articles de santé pour vous tenir au courant de l'actualité médicale .
								</p>
							</div>
						</div>
					</div>
					<div className='col-md-6'>
						<div className="cardC">
							<div className="cardC-image">
								<img  height='100%' width='50%' />
							</div>
							<div className="cardC-description">
								<p className="text-title">Prenez un rendez vous avec médécin</p>
								<p className="textC-body">
								Quand on a pas envie de passer 45 minutes à 2 heures dans une salle d’attente, prendre un rendez-vous permet de gérer au mieux son emploi de temps. OuiCare vous donnez également la possibilité de selectionner un médecin vivant près de chez vous , très utile lorsqu'on est à la recherche d'un spécialiste.
								</p>
							</div>
						</div>
					</div>
				</div>
			</main>
	 <FooterGen/>
  </>
  )
}

export default ServicesPage